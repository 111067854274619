// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
import Header from "layouts/userprofile/components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { myProfileThunk } from "redux/Thunks/UserManagement";

// Data

function Overview() {
  const [profileDate, setProfileData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await dispatch(myProfileThunk());
      if (res.payload.status === 200) {
        setProfileData(res.payload.data.data);
      }
    })();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description="Hi, Our company is dedicated to providing personalized loan solutions to help you achieve your financial goals. We offer a range of products tailored to meet your needs with flexibility and transparency."
                info={{
                  fullName: `${profileDate.firstName} ${profileDate.lastName}`,
                  mobile: profileDate.mobileNumber,
                  email: profileDate.email,
                }}
                social={
                  [
                    // {
                    //   link: "https://www.facebook.com/CreativeTim/",
                    //   icon: <FacebookIcon />,
                    //   color: "facebook",
                    // },
                    // {
                    //   link: "https://twitter.com/creativetim",
                    //   icon: <TwitterIcon />,
                    //   color: "twitter",
                    // },
                    // {
                    //   link: "https://www.instagram.com/creativetimofficial/",
                    //   icon: <InstagramIcon />,
                    //   color: "instagram",
                    // },
                  ]
                }
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
