import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const payBill = createAsyncThunk("oaybill", async (body) => {
  const res = await ApiService.post(
    `paysprint/bill-payment`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const fetchBill = createAsyncThunk("fetch-bill", async (data) => {
  const res = await ApiService.post(
    `paysprint/fetch-bill/${data.type}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const createPaymentOrder = createAsyncThunk("create-payment-order", async (body) => {
  const res = await ApiService.post(
    `payment`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const verifyPayment = createAsyncThunk("verify-payment", async (body) => {
  const res = await ApiService.post(
    `payment/verify`,
    { ...body },
    {
      headers: {
        Authorization: `Bearer ${Sessions.userToken}`,
      },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const getAllBills = createAsyncThunk("fetch-bill", async (param) => {
  const res = await ApiService.get(`payment${param ? `?${param}` : ""}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export default payBill;
