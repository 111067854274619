import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import Constants, { defaultData } from "utils/Constants";
import loanConfigThunk, { getAllSubmitterLoanFormThunk } from "redux/Thunks/LoanFormConfig";

const initialState = {
  loading: "idle",
  refetchConfig: true,
  loanConfig: [],
  screens: [
    {
      name: "Credit Card",
      id: defaultData.CREDIT_CARD_SCREEN_ID,
      index: 4,
      screensInfo: {},
    },
    {
      name: "Personal Loan",
      id: defaultData.PERSONAL_LOAN_SCREEN_ID,
      index: 1,
      screensInfo: {},
    },
    {
      name: "Home Loan",
      id: defaultData.HOME_LOAN_SCREEN_ID,
      index: 0,
      screensInfo: {},
    },
    {
      name: "Business Loan",
      id: defaultData.BUSINESS_LOAN_SCREEN_ID,
      index: 2,
      screensInfo: {},
    },
    {
      name: "Car Loan",
      id: defaultData.CAR_LOAN_SCREEN_ID,
      index: 3,
      screensInfo: {},
    },

    {
      name: "Education Loan",
      id: defaultData.EDUCATION_LOAN_SCREEN_ID,
      index: 5,
      screensInfo: {},
    },
  ],
  dataLoading: "idle",
  loanData: [],
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateLoanData(state, action) {
      const mongooseId = "_id";
      const index = state.loanData.findIndex(
        (item) => item?.[mongooseId] === action.payload[mongooseId]
      );
      state.loanData[index] = action.payload;
    },
  },

  extraReducers: {
    [loanConfigThunk.pending]: (state) => {
      state.loading = Constants.PENDING;
    },
    [loanConfigThunk.fulfilled]: (state, action) => {
      state.loading = Constants.FULFILLED;
      state.loanConfig[0] = action.payload.data;
      action.payload.data.data.screens.forEach((element, sIndex) => {
        const screenIndex = state.screens.findIndex((screen) => screen.id === element.screenId);
        if (screenIndex !== -1) {
          state.screens[screenIndex].index = sIndex;
          state.screens[screenIndex].screensInfo = element;
        }
      });
      state.refetchConfig = false;
    },
    [loanConfigThunk.rejected]: (state) => {
      state.loading = Constants.REJECTED;
    },
    [getAllSubmitterLoanFormThunk.pending]: (state) => {
      if (state.loanData.length === 0) state.dataLoading = Constants.PENDING;
    },

    [getAllSubmitterLoanFormThunk.fulfilled]: (state, { payload }) => {
      state.dataLoading = Constants.FULFILLED;
      // Use `byPassSlice: true` to avoid loading data into the slice.
      // For example, this is useful when calling an API to retrieve data for filters.
      // state.loanData = payload.data.data;
      if (!payload.byPassSlice && payload.type === "add") state.loanData = payload.data.data;
      else if (!payload.byPassSlice) state.loanData.push(...payload.data.data);
    },

    [getAllSubmitterLoanFormThunk.rejected]: (state) => {
      state.dataLoading = Constants.REJECTED;
    },

    [resetStateThunk.fulfilled]: (state) => {
      state.loading = Constants.IDLE;
      state.equipmentLoading = Constants.IDLE;
      state.refetchConfig = true;
      state.config = [];
      state.screens = initialState.screens;
    },
  },
});

export const { updateLoanData } = configSlice.actions;

export default configSlice.reducer;
