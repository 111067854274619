import React, { useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import BillForm from "examples/modal/BillForm";
import CustomButton from "examples/NewDesign/CustomButton";
import BillPaymentsListing from "layouts/billPayments/BillsPaymentListing";

// Constant
import { PageTitles, Icons, Colors } from "utils/Constants";

function ProfileForm() {
  const [open, seOpen] = useState(false);
  const [billTitle, setBillTitle] = useState("");

  const handleBillForm = (type) => {
    setBillTitle(type);
    seOpen(true);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {open && (
        <BillForm
          open={open}
          handleClose={() => seOpen(false)}
          title={billTitle}
          actionButton="Fetch"
        />
      )}
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.UTILITY_BILL_PAYMENTS} />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title={PageTitles.ELECTRICITY}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleBillForm(PageTitles.ELECTRICITY)}
          />
          <CustomButton
            title={PageTitles.WATER}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleBillForm(PageTitles.WATER)}
          />
          <CustomButton
            title={PageTitles.GAS}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleBillForm(PageTitles.GAS)}
          />
          <CustomButton
            title={PageTitles.FASTAG}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleBillForm(PageTitles.FASTAG)}
          />
          <CustomButton
            title={PageTitles.MUNCIPAILITY}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => handleBillForm(PageTitles.MUNCIPAILITY)}
          />
        </MDBox>
      </MDBox>
      <BillPaymentsListing />
    </DashboardLayout>
  );
}

export default ProfileForm;
