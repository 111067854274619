import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import { useDispatch } from "react-redux";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import pxToRem from "assets/theme/functions/pxToRem";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
// Validations Rules
import Validations from "utils/Validations/index";
import { loginThunk } from "redux/Thunks/Authentication";

// Sessions
import Sessions from "utils/Sessions";
import jwtDecode from "jwt-decode";
import { defaultData, Colors } from "utils/Constants";

// Images
import logo from "assets/images/full-logo.png";
import bgImage from "assets/images/auth-bgi.png";
import welcomeLogo from "assets/images/Welcome.svg";

function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [credentialError, setCredentialError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleValidation = () => {
    const emailValidate = Validations.validate("email", email, null, null, true);
    const passwordValidate = Validations.validate("password", password, 6, 30, true);
    if (emailValidate !== "" || passwordValidate !== "") {
      setEmailError(emailValidate);
      setPasswordError(passwordValidate);
      return false;
    }
    return true;
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    setLoading(true);
    const val = handleValidation();
    if (val) {
      const body = { email, password, isMobile: false };

      const res = await dispatch(loginThunk(body));
      setLoading(false);
      if (res.payload.status !== 200) {
        setCredentialError(res.payload.data.message);
      } else {
        setCredentialError(res.data);
      }

      if (res.payload.status === 200) {
        if (email !== "") {
          Sessions.setUserToken(res.payload.data.data.token);
          Sessions.setUserEmail(email);
        }
        const deocdedToken = jwtDecode(res.payload.data.data.token);
        if (deocdedToken.mobileNumber) Sessions.setMobileNumber(deocdedToken.mobileNumber);
        Sessions.setUserFullName(deocdedToken.name);
        navigate(
          deocdedToken.role === defaultData.SUPER_ADMIN_ROLE ? "admin/home" : "client/setting"
        );
      }
    }
    setLoading(false);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError("");
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordError("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100vh"
      >
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <MDBox component="img" src={logo} alt="Brand" width={pxToRem(317)} marginTop="22px" />
        </MDBox>
        <MDBox component="img" src={welcomeLogo} alt="Brand" width="13rem" marginTop="42px" />

        <MDTypography textAlign="center" color="error" mt={1}>
          {credentialError !== "" ? credentialError : null}
        </MDTypography>
        <MDBox pt={4} pb={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                type="email"
                placeholder="Enter Your Email Here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon fontSize="medium" sx={{ color: Colors.PRIMARY }} />
                    </InputAdornment>
                  ),
                }}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
              />
              <MDTypography variant="caption" color="error" display="flex" mt={1}>
                {emailError}
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  "& input": {
                    fontSize: "16px",
                    color: "#667085",
                  },
                }}
                type={showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                placeholder="Enter Password Here"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon fontSize="medium" sx={{ color: Colors.PRIMARY }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPasswordClick}>
                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleKeyDown}
              />
              <MDTypography variant="button" color="error" mt={1} display="flex">
                {passwordError}
              </MDTypography>
              <MDTypography
                component={Link}
                to="/authentication/forgot-password"
                variant="caption"
                sx={{
                  mt: 1,
                  color: Colors.WHITE,
                  fontSize: pxToRem(16),
                  fontWeight: 500,
                  float: "right",
                }}
              >
                Forgot Your Password?
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              <MDButton
                variant="gradient"
                sx={{
                  mt: pxToRem(30),
                  width: "100%",
                  textTransform: "capitalize",
                  background: "#012F82",
                  color: Colors.WHITE,
                  padding: 2,
                  fontSize: pxToRem(16),
                  fontWeight: 700,
                  "&:hover": { background: "#012F82" },
                }}
                disabled={loading}
                onClick={handleLogin}
                fullWidth
              >
                {loading ? `Loading...` : "Sign In"}
              </MDButton>
            </MDBox>
            <MDBox textAlign="center" mt={4}>
              <MDTypography
                variant="caption"
                sx={{ color: Colors.WHITE, fontSize: pxToRem(12), fontWeight: 400 }}
                fontWeight="light"
              >
                © {new Date().getFullYear()} made by Finvibes. All rights reserved.
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
