import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import Constants, { defaultData } from "utils/Constants";
import Author from "components/Table/Author";

export default function SetupReportData({
  bankOpeningSubmittedFormLoading,
  bankOpeningSubmittedForm,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (bankOpeningSubmittedFormLoading === Constants.FULFILLED) {
      const list = bankOpeningSubmittedForm.map((item, index) => {
        const temp = {
          srNo: (
            <MDTypography variant="caption" color="text">
              {index + 1}
            </MDTypography>
          ),
          name: (
            <Author
              name={item?.beneficiary_name}
              style={{ textTransform: "normal" }}
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
            />
          ),
          email: <Author name={item?.beneficiary_email} style={{ textTransform: "normal" }} />,
          phone: <Author name={item?.beneficiary_mobile_no} style={{ textTransform: "normal" }} />,
          pan: <Author name={item?.beneficiary_pan} style={{ textTransform: "normal" }} />,
          aadhar: (
            <Author
              name={item?.beneficiary_adhar}
              style={{ textTransform: "normal" }}
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
            />
          ),
          category: (
            <Author name={item?.bank_opening_category} style={{ textTransform: "normal" }} />
          ),
          bank: <Author name={item?.bank_opening_name} style={{ textTransform: "normal" }} />,
        };

        return temp;
      });
      setRows([...list]);
    }
  }, [bankOpeningSubmittedFormLoading, bankOpeningSubmittedForm]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "5%", align: "left" },
      { Header: "Name", accessor: "name", width: "25%", align: "left" },
      { Header: "Email", accessor: "email", width: "25%", align: "left" },
      { Header: "Mobile", accessor: "phone", width: "25%", align: "left" },
      { Header: "Pan", accessor: "pan", width: "25%", align: "left" },
      { Header: "Aadhar", accessor: "aadhar", align: "left" },
      { Header: "Category", accessor: "category", align: "left" },
      { Header: "Bank", accessor: "bank", align: "left" },
    ],
    rows,
  };
}
