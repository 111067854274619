import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import BillsPaymentTable from "dataTables/BillsPayments/BillsPaymentTable";
import DataTable from "examples/Tables/DataTable";
import ResetFilterButton from "components/Buttons/ResetButton";
import FilterDropdown from "components/Dropdown/FilterDropdown";

// Constant
import Constants, { defaultData } from "utils/Constants";

// Redux component
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch } from "react-redux";
import { getAllBills } from "redux/Thunks/BillPayment";
import { paramCreater } from "utils/methods/methods";

function feedbacks() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "category",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "Broadband", title: "Broadband" },
        { [Constants.MONGOOSE_ID]: "Electricity", title: "Electricity" },
        { [Constants.MONGOOSE_ID]: "Gas", title: "Gas" },
        { [Constants.MONGOOSE_ID]: "Insurance", title: "Insurance" },
        { [Constants.MONGOOSE_ID]: "Water", title: "Water" },
        { [Constants.MONGOOSE_ID]: "PREPAID", title: "PREPAID" },
        { [Constants.MONGOOSE_ID]: "Postpaid", title: "Postpaid" },
        { [Constants.MONGOOSE_ID]: "DTH", title: "DTH" },
        { [Constants.MONGOOSE_ID]: "Landline", title: "Landline" },
        { [Constants.MONGOOSE_ID]: "Cable", title: "Cable" },
        { [Constants.MONGOOSE_ID]: "Fastag", title: "Fastag" },
        { [Constants.MONGOOSE_ID]: "LPG", title: "LPG" },
        { [Constants.MONGOOSE_ID]: "Municipality", title: "Municipality" },
      ],
      selectedValue: "all",
    },
    {
      inputLabel: "status",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "success", title: "Sucesss" },
        { [Constants.MONGOOSE_ID]: "failed", title: "Failed" },
      ],
      selectedValue: "all",
    },
  ]);
  const [loanData, setLoanData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);

  const dispatch = useDispatch();

  const { columns, rows } = BillsPaymentTable(loanData);

  const handleFilter = async (temp = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(temp[0].selectedValue !== "all" && { category: temp[0].selectedValue }),
      ...(temp[1].selectedValue !== "all" && { status: temp[1].selectedValue }),
    };
    const res = await dispatch(getAllBills(paramCreater(paramData)));
    if (res.payload.status === 200) {
      setLoanData(res.payload.data.data);
      setLoading(Constants.FULFILLED);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(getAllBills(paramCreater(data)));
    if (res.payload.status === 200) {
      setLoanData([...loanData, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === name);
    temp[index].selectedValue = value;
    setFilters(temp);
    handleFilter(temp);
  };

  const handleReset = async () => {
    const resetFilters = filters.map((filter) => ({ ...filter, selectedValue: "all" }));
    setFilters(resetFilters);
    await handleFilter(resetFilters);
  };

  return (
    <>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex wrap" flexWrap="wrap" justifyContent="start">
          {filters?.map((val) => (
            <FilterDropdown
              label={val.inputLabel}
              name={val.inputLabel}
              defaultValue={val?.selectedValue}
              value={val?.selectedValue}
              handleChange={handleFilterChange}
              menu={val.list}
              key={val.inputLabel}
              maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
            />
          ))}
          <ResetFilterButton handleReset={handleReset} style={{ marginLeft: "1rem" }} />
        </MDBox>
      </MDBox>

      <MDBox mt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loading={loading}
          handleTablePagination={handleTablePagination}
          currentPage={tablePagination.page}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>

      {/* Edit Lead Data */}
    </>
  );
}

export default feedbacks;
