import { createSlice } from "@reduxjs/toolkit";
import { resetStateThunk } from "redux/Thunks/Authentication";
import { getAllBanksOpeningList } from "redux/Thunks/BankOpening";
import Constants from "utils/Constants";

const initialState = {
  bankOpeningLoading: Constants.IDLE,
  bankOpeningList: [],
};

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    relaodBankOpeningList: (state) => {
      state.bankOpeningLoading = Constants.PENDING;
    },
  },

  extraReducers: {
    [getAllBanksOpeningList.pending]: (state) => {
      if (state.bankOpeningList.length === 0) state.bankOpeningLoading = Constants.PENDING;
    },
    [getAllBanksOpeningList.fulfilled]: (state, { payload }) => {
      state.bankOpeningLoading = Constants.FULFILLED;
      if (payload.type === "add") state.bankOpeningList = payload.data.data;
      else state.bankOpeningList.push(...payload.data);
    },
    [getAllBanksOpeningList.rejected]: (state) => {
      state.bankOpeningLoading = Constants.REJECTED;
    },
    [resetStateThunk.fulfilled]: (state) => {
      state.bankOpeningList = [];
      state.reportDetails = [];
    },
  },
});

export const { relaodBankOpeningList } = reportSlice.actions;
export default reportSlice.reducer;
