import React, { useEffect, useState } from "react";

// MUI Components
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTypography from "components/MDTypography";

// Custom Components
import DoughnutPieChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Constant
import { Colors, Icons } from "utils/Constants";

// Methods
import { capitalizeFirstLetter } from "utils/methods/methods";

// 3rd party
import PropTypes from "prop-types";

const qhseCardStatuses = ["Open", "Close"];
const qhseCardTypes = [
  "Document Verification",
  "Application Submitted",
  "Pending with Credit",
  "Offer Received",
  "Offer Selected",
  "Loan Sanctioned",
  "Disbursement Documentation",
  "Disbursement",
  "Amount Credited/Cheque issued",
];

const chartColors = (title) => {
  let colors;
  switch (title) {
    // Open and Unsafe status
    case "open":
      colors = {
        backgroundColor: Colors.LIGHT_BROWN,
        fontColor: Colors.DARK_BROWN,
      };
      break;

    // Closed and Safe status
    case "close":
      colors = {
        backgroundColor: Colors.LIGHT_GREEN2,
        fontColor: Colors.DARK_GREEN2,
      };
      break;

    // Customize for the provided currentStatus list
    case "Document Verification":
      colors = {
        backgroundColor: Colors.LIGHT_PURPLE,
        fontColor: Colors.DARK_PURPLE,
      };
      break;

    case "Application Submitted":
      colors = {
        backgroundColor: Colors.LIGHT_TEAL,
        fontColor: Colors.DARK_TEAL,
      };
      break;

    case "Pending with Credit":
      colors = {
        backgroundColor: Colors.LIGHT_YELLOW,
        fontColor: Colors.DARK_YELLOW,
      };
      break;

    case "Offer Received":
      colors = {
        backgroundColor: Colors.LIGHT_PINK,
        fontColor: Colors.DARK_PINK,
      };
      break;

    case "Offer Selected":
      colors = {
        backgroundColor: Colors.LIGHT_GRAY6,
        fontColor: Colors.DARK_GRAY,
      };
      break;

    case "Loan Sanctioned":
      colors = {
        backgroundColor: Colors.LIGHT_CYAN,
        fontColor: Colors.DARK_CYAN,
      };
      break;

    case "Disbursement Documentation":
      colors = {
        backgroundColor: Colors.LIGHT_LIME,
        fontColor: Colors.DARK_LIME,
      };
      break;

    case "Disbursement":
      colors = {
        backgroundColor: Colors.LIGHT_MINT,
        fontColor: Colors.DARK_MINT,
      };
      break;

    case "Amount Credited/Cheque issued":
      colors = {
        backgroundColor: Colors.LIGHT_BLUE2,
        fontColor: Colors.DARK_BLUE2,
      };
      break;

    // Default case for other statuses
    default:
      colors = {
        backgroundColor: Colors.LIGHT_GRAY,
        fontColor: Colors.DARK_GREY,
      };
      break;
  }
  return colors;
};

function LeadBayChart({ chartData }) {
  // Initialize state variables
  const [cardStatusCount, setCardStatusCount] = useState([]);
  const [cardTypeCount, setCardCurrentStatusCount] = useState([]);
  const [combinedCountList, setCombinedCountList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  // Fetch and process dashboard data
  const qhseDashboardData = async () => {
    const statusList = chartData.data[0]?.statusData || [];
    const currentStatusList = chartData.data[0]?.currentStatusData || [];

    const cardStatusCountList = await Promise.all(
      statusList.map(async (item) => {
        const colors = chartColors(item.status);
        const { backgroundColor, fontColor } = colors;
        return {
          name: capitalizeFirstLetter(item.status),
          value: item.count,
          color: backgroundColor,
          textColor: fontColor,
        };
      })
    );

    const cardTypeCountList = await Promise.all(
      currentStatusList.map(async (item) => {
        const { backgroundColor, fontColor } = chartColors(item.currentStatus);
        return {
          name: item.currentStatus,
          value: item.count,
          color: backgroundColor,
          textColor: fontColor,
        };
      })
    );

    await Promise.all(
      qhseCardTypes.map(async (item) => {
        const exist = cardTypeCountList.some((card) => card.name === item);
        if (!exist) {
          const { backgroundColor, fontColor } = chartColors(item);
          cardTypeCountList.push({
            name: item,
            value: 0,
            color: backgroundColor,
            textColor: fontColor,
          });
        }
      })
    );

    await Promise.all(
      qhseCardStatuses.map(async (item) => {
        const exist = cardStatusCountList.some((card) => card.name === item);
        if (!exist) {
          const { backgroundColor, fontColor } = chartColors(item);
          cardStatusCountList.push({
            name: item,
            value: 0,
            color: backgroundColor,
            textColor: fontColor,
          });
        }
      })
    );

    setCardStatusCount(cardStatusCountList);
    setCardCurrentStatusCount(cardTypeCountList);
    setCombinedCountList([...cardStatusCountList, ...cardTypeCountList]);
    setTotalCount(chartData.data[0].total?.[0].count || 0);
  };

  // Fetch and process filters

  // Effect to load data on component mount
  useEffect(() => {
    if (!chartData.loading) qhseDashboardData("all");
  }, [chartData.loading]);

  return (
    <MDBox
      sx={{
        paddingBottom: 3,
      }}
    >
      {!chartData.loading ? (
        <Card sx={{ marginTop: 3, height: "fit-content", paddingBottom: 3 }}>
          <MDBox>
            <MDTypography
              sx={{
                fontWeight: "600",
                fontSize: pxToRem(26),
                lineHeight: pxToRem(38),
                textAlign: "center",
                backgroundColor: "#191A51",
                color: "#ffffff",
                paddingX: pxToRem(10),
                paddingY: pxToRem(16),
                borderRadius: "0.75rem 0.75rem 0 0",
              }}
            >
              Overview of Lead Bay
            </MDTypography>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: {
                  lg: "space-between",
                  md: "center",
                  sm: "center",
                  xs: "center",
                },
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "start",
                paddingX: pxToRem(30),
                paddingY: pxToRem(20),
              }}
            >
              <MDBox>
                <DoughnutPieChart
                  data={cardTypeCount}
                  title="Lead Bay Cards by Type"
                  totalCount={totalCount}
                />
                <DoughnutPieChart
                  data={cardStatusCount}
                  title="Lead Bay Cards by Status"
                  totalCount={totalCount}
                />
              </MDBox>
              <MDBox
                sx={{
                  marginTop: pxToRem(25),
                }}
              >
                <MDBox
                  sx={{
                    padding: pxToRem(20),
                    marginTop: pxToRem(20),
                    border: `4px solid ${Colors.LIGHT_GRAY}`,
                    borderRadius: pxToRem(24),
                    marginRight: pxToRem(20),
                    display: "flex",
                    flexDirection: { lg: "column", md: "row", sm: "row", xs: "column" },
                    gap: pxToRem(20),
                    flexWrap: "wrap",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  {combinedCountList.map((item) => {
                    if (item.name === "Open" || item.name === "Close") {
                      return (
                        <MDBox
                          key={item.name}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: pxToRem(180),
                            height: pxToRem(130),
                            backgroundColor: item.color,
                            borderRadius: pxToRem(16),
                            gap: pxToRem(1),
                          }}
                        >
                          <MDTypography
                            sx={{
                              fontSize: pxToRem(40),
                              fontWeight: "bold",
                              color: item.textColor,
                            }}
                          >
                            {item.value}
                          </MDTypography>
                          <MDTypography
                            sx={{
                              fontSize: pxToRem(20),
                              fontWeight: "bold",
                              color: item.textColor,
                            }}
                          >
                            {item.name}
                          </MDTypography>
                        </MDBox>
                      );
                    }
                    return null;
                  })}
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {Icons.LOADING2}
        </MDBox>
      )}
    </MDBox>
  );
}

LeadBayChart.propTypes = {
  chartData: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default LeadBayChart;
