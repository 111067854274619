import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService, { fetchAPIService } from "../ApiService/ApiService";

const loanConfigThunk = createAsyncThunk("loan-config/api", async () => {
  const res = await ApiService.get(`loan/config`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const submitLoanFormThunk = createAsyncThunk("loan-form-submit/api", async (data) => {
  const res = await ApiService.post(
    `loan/${data.type}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const getAllSubmitterLoanFormThunk = createAsyncThunk("loan-form-get/api", async (param) => {
  const res = await ApiService.get(`loan${param ? `?${param}` : ""}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);

  const params = new URLSearchParams(param);
  const page = params.get("page");
  return page === "0"
    ? { data: res.data, type: "add", status: res.status, byPassSlice: false }
    : { data: res.data, type: "append", status: res.status, byPassSlice: false };
});

export const updateLeadDataThunk = createAsyncThunk("loan-form-update/api", async (data) => {
  const res = await ApiService.patch(
    `loan/${data.id}`,
    { ...data.body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export const uploadInventoryFromExcel = createAsyncThunk("upload-invenotory", async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const res = await ApiService.post(`loan/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Sessions.userToken}`,
    },
  })
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const exportLoanThunk = createAsyncThunk("export/api", async () => {
  const res = await fetchAPIService(`/loan/export`, {
    method: "GET",
  }).then((response) => response.blob());
  return res;
});

export const getLeadByChartsThunk = createAsyncThunk("lead-by-charts/api", async (param) => {
  const res = await ApiService.get(`loan/chart?${param ?? ""}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((error) => error.response);
  return res;
});

export default loanConfigThunk;
