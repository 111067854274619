/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";

import Author from "components/Table/Author";

export default function data(loanData) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (loanData) {
      const list = loanData.map((item, index) => {
        const temp = {
          srNo: <Author name={index + 1} />,
          name: <Author name={`${item.bill_fetch.userName}`} />,
          canumber: <Author name={item.canumber} />,
          category: <Author name={item.category} />,
          billAmount: <Author name={item.bill_fetch.billAmount} />,
          billDate: <Author name={item.bill_fetch.billdate} />,
          mode: <Author name={item.mode} />,
          referenceId: <Author name={item.referenceid} />,
          status: <Author name={item.status} />,
        };
        return temp;
      });
      setRows([...list]);
    }
  }, [loanData]);

  return {
    columns: [
      { Header: "No.", accessor: "srNo", width: "3%" },
      { Header: "Name", accessor: "name", width: "10%" },
      { Header: "CustomerId", accessor: "canumber", width: "10%" },
      { Header: "Category", accessor: "category", width: "10%" },
      { Header: "Bill Amount", accessor: "billAmount", width: "10%" },
      { Header: "Bill Date", accessor: "billDate", width: "10%" },
      { Header: "Mode", accessor: "mode", width: "10%" },
      { Header: "Reference ID", accessor: "referenceId", width: "10%" },
      { Header: "Status", accessor: "status", width: "10%" },
    ],
    rows,
  };
}
