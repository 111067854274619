import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;

export default function MultiSearchBar({ searchOptions, setSearchOptions }) {
  const [tempMenuOptions, setTempMenuOptions] = React.useState([...searchOptions]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchOptions(tempMenuOptions);
  };

  const handleMenuItemClick = (event) => {
    event.stopPropagation(); // Prevent closing the menu
  };

  const handleCheckboxChange = (event) => {
    const tempOptions = [...searchOptions];
    const index = tempOptions.findIndex((option) => option.id === event.target.id);
    tempOptions[index].checked = event.target.checked;
    setTempMenuOptions(tempOptions);
  };

  // Close menu on click outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, open]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          mt: 5.5,
          mr: 1,
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "25ch",
          },
        }}
      >
        {searchOptions.map((option) => (
          <FormGroup key={option.id}>
            <MenuItem onClick={handleMenuItemClick}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={option.checked}
                    id={option.id}
                    onChange={handleCheckboxChange}
                  />
                }
                label={option.title}
                id={option.id}
              />
            </MenuItem>
          </FormGroup>
        ))}
      </Menu>
    </div>
  );
}

MultiSearchBar.propTypes = {
  searchOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchOptions: PropTypes.func.isRequired,
};
