import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import LoanDataTable from "dataTables/submittedLoan/submitterLoan";
import DataTable from "examples/Tables/DataTable";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import ConfigDropdown from "components/Dropdown/ConfigDropdown";
import FDropdown2 from "components/Dropdown/fDropdown2";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import SearchMenu from "examples/multiSearchBar/MultiSearchBar";
import ResetFilterButton from "components/Buttons/ResetButton";
import CustomSearch from "components/Search/Search";
import UpdateLeadBay from "examples/modal/UpdateLeadBay";
import FilterDropdown from "components/Dropdown/FilterDropdown";

// Constant
import Constants, { defaultData, ModalContent, ButtonTitles, Icons, Colors } from "utils/Constants";

// Redux component
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubmitterLoanFormThunk,
  updateLeadDataThunk,
  exportLoanThunk,
} from "redux/Thunks/LoanFormConfig";
import getAllBanks, { getAllCodes } from "redux/Thunks/leadUtilsThunks";
import FTextField from "components/Form/FTextField";
import pxToRem from "assets/theme/functions/pxToRem";
import { paramCreater } from "utils/methods/methods";
import { updateLoanData } from "redux/Slice/LoanConfig.slice";

const initialEditModal = {
  open: false,
  id: "",
  status: "open",
  sourceOfLead: "",
  imd: "",
  bank: "",
  code: "",
  currentStatus: "",
  dateOfDisbursment: "",
  remark: "",
};

function feedbacks() {
  const [search, setSearch] = useState([
    {
      inputLabel: "Search By First Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "First Name",
      checked: true,
      id: "firstName",
      index: 0,
    },
    {
      inputLabel: "Search By Last Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Last Name",
      checked: false,
      id: "lastName",
      index: 1,
    },
    {
      inputLabel: "Search By Mobile Number",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Mobile Number",
      checked: false,
      id: "mobileNumber",
      index: 2,
    },
    {
      inputLabel: "Search By IMD",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "IMD",
      checked: false,
      id: "imd",
      index: 3,
    },
  ]);
  const [editLoading, setEditLoading] = useState(false);
  const [editModal, setEditModal] = useState(initialEditModal);
  const [screenIndex, setScreenIndex] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [updateLeadbay, setUpdateLeadBay] = useState({});
  const [leadyBayId, setLeadyBayId] = useState("");
  const [error, setError] = useState({});
  const leadSources = ["Direct Offline", "Direct Online", "IMD Channel"];
  const currentStatus = [
    { [Constants.MONGOOSE_ID]: "Document Verification", title: "Document Verification" },
    { [Constants.MONGOOSE_ID]: "Application Submitted", title: "Application Submitted" },
    { [Constants.MONGOOSE_ID]: "Pending with Credit", title: "Pending with Credit" },
    { [Constants.MONGOOSE_ID]: "Offer Received", title: "Offer Received" },
    { [Constants.MONGOOSE_ID]: "Offer Selected", title: "Offer Selected" },
    { [Constants.MONGOOSE_ID]: "Loan Sanctioned", title: "Loan Sanctioned" },
    { [Constants.MONGOOSE_ID]: "Disbursement Documentation", title: "Disbursement Documentation" },
    { [Constants.MONGOOSE_ID]: "Disbursement", title: "Disbursement" },
    {
      [Constants.MONGOOSE_ID]: "Amount Credited/Cheque issued",
      title: "Amount Credited/Cheque issued",
    },
  ];
  const [filters, setFilters] = useState([
    {
      inputLabel: "Current Status",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }, ...currentStatus],
      selectedValue: "all",
    },
    {
      inputLabel: "Status",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "open", title: "Open" },
        { [Constants.MONGOOSE_ID]: "close", title: "Close" },
      ],
      selectedValue: "all",
    },
    {
      inputLabel: "Month",
      list: [
        { [Constants.MONGOOSE_ID]: "all", title: "All" },
        { [Constants.MONGOOSE_ID]: "jan", title: "Jan" },
        { [Constants.MONGOOSE_ID]: "feb", title: "Feb" },
        { [Constants.MONGOOSE_ID]: "mar", title: "Mar" },
        { [Constants.MONGOOSE_ID]: "apr", title: "Apr" },
        { [Constants.MONGOOSE_ID]: "may", title: "May" },
        { [Constants.MONGOOSE_ID]: "jun", title: "Jun" },
        { [Constants.MONGOOSE_ID]: "jul", title: "Jul" },
        { [Constants.MONGOOSE_ID]: "aug", title: "Aug" },
        { [Constants.MONGOOSE_ID]: "sep", title: "Sep" },
        { [Constants.MONGOOSE_ID]: "oct", title: "Oct" },
        { [Constants.MONGOOSE_ID]: "nov", title: "Nov" },
        { [Constants.MONGOOSE_ID]: "dec", title: "Dec" },
      ],
      selectedValue: "all",
    },
    {
      inputLabel: "Bank",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
    {
      inputLabel: "Code",
      list: [{ [Constants.MONGOOSE_ID]: "all", title: "All" }],
      selectedValue: "all",
    },
  ]);
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);

  const loanData = useSelector((state) => state.loan);

  let debounceTimeout;

  const [bankList, setBankList] = useState([]);
  const [codeList, setCodeList] = useState([]);
  const dispatch = useDispatch();

  const handleEditModal = (data) => {
    setEditModal({
      open: true,
      data,
      id: data?.[Constants.MONGOOSE_ID],
      status: data?.status,
      sourceOfLead: data?.sourceOfLead,
      imd: data?.imd,
      bank: data?.bank?.[Constants.MONGOOSE_ID],
      code: data?.code?.[Constants.MONGOOSE_ID],
      currentStatus: data?.currentStatus,
      remark: data?.remark,
      dateOfDisbursment: data?.dateOfDisbursment ? data.dateOfDisbursment.split("T")[0] : "",
    });
  };

  const handleScreenIndex = (type) => {
    switch (type) {
      case "home":
        return 2;
      case "business":
        return 3;
      case "personal":
        return 1;
      case "car":
        return 4;
      case "credit":
        return 0;
      case "education":
        return 5;
      case "credit card":
        return 0;
      default:
        return 0;
    }
  };

  const handleBasicEditModal = (item) => {
    const id = "_id";
    setScreenIndex(handleScreenIndex(item?.type));
    setLeadyBayId(item[id]);
    const temp = {
      type: item?.type ? item?.type : "",
      pinCode: item?.pinCode ? item?.pinCode : "",
      mobileNumber: item.mobileNumber ? `+91${item.mobileNumber}` : "",
      loanAmount: item.loanAmount ? item.loanAmount : "",
      totalAmountOfEmi: item.totalAmountOfEmi ? item.totalAmountOfEmi : "",
      homeLoanType: item.homeLoanType ? item.homeLoanType : "",
      firstName: item.firstName ? item.firstName : "",
      lastName: item.lastName ? item.lastName : "",
      currentEmi: item.currentEmi ? "yes" : "no",
      email: item.email ? item.email : "",
      dateOfBirth: item.dateOfBirth ? item.dateOfBirth : "",
      dateOfApplication: item.dateOfApplication ? item.dateOfApplication : "",
      confirmIfYouAreIndianNational: item.confirmIfYouAreIndianNational
        ? item.confirmIfYouAreIndianNational
        : "",
      termsAndConditions: item.termsAndConditions ? item.termsAndConditions : "",
      panCardNumber: item.panCardNumber ? item.panCardNumber : "",
      employmentType: item.employmentType ? item.employmentType : "",
      salaryBankAccountBankName: item.salaryBankAccountBankName
        ? item.salaryBankAccountBankName
        : "",
      netMonthlySalaryCredit: item.netMonthlySalaryCredit ? item.netMonthlySalaryCredit : "",
      lookingForCurrentLoanOutstandingTransfer: item.lookingForCurrentLoanOutstandingTransfer
        ? item.lookingForCurrentLoanOutstandingTransfer
        : "",
      primaryBankAccountBankName: item.primaryBankAccountBankName
        ? item.primaryBankAccountBankName
        : "",
      annualIncomeAsPerItr: item.annualIncomeAsPerItr ? item.annualIncomeAsPerItr : "",
      carLoanType: item.carLoanType ? item.carLoanType : "",
      dynamicFields: item.dynamicFields ? item.dynamicFields : [],
      onRoadPrice: item.onRoadPrice ? item.onRoadPrice : "",
      modelOfCar: item.modelOfCar ? item.modelOfCar : "",
      makerOfCar: item.makerOfCar ? item.makerOfCar : "",
      haveAnycreditCard: item.haveAnycreditCard ? "yes" : "no",
      creditLimit: item.creditLimit ? item.creditLimit : "",
      existingCreditCardBankName: item.existingCreditCardBankName
        ? item.existingCreditCardBankName
        : "",
      images: item?.images || [],
    };
    setUpdateLeadBay({ ...temp });
    setOpenEdit(true);
  };

  const handleCloseEditModal = () => {
    setEditModal({ open: false, ...initialEditModal });
  };

  const { columns, rows } = LoanDataTable(loanData.loanData, handleEditModal, handleBasicEditModal);

  const handleFilter = async (tempSearch = search, tempFilters = filters) => {
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(tempSearch[0].selectedValue !== "" && { firstName: tempSearch[0].selectedValue }),
      ...(tempSearch[1].selectedValue !== "" && { lastName: tempSearch[1].selectedValue }),
      ...(tempSearch[2].selectedValue !== "" && {
        mobileNumber: tempSearch[2].selectedValue,
      }),
      ...(tempSearch[3].selectedValue !== "" && {
        imd: tempSearch[3].selectedValue,
      }),
      ...(tempFilters[0].selectedValue !== "all" && {
        currentStatus: tempFilters[0].selectedValue,
      }),
      ...(tempFilters[1].selectedValue !== "all" && {
        status: tempFilters[1].selectedValue,
      }),
      ...(tempFilters[2].selectedValue !== "all" && {
        month: tempFilters[2].selectedValue,
      }),
      ...(tempFilters[3].selectedValue !== "all" && {
        bank: tempFilters[3].selectedValue,
      }),
      ...(tempFilters[4].selectedValue !== "all" && {
        code: tempFilters[4].selectedValue,
      }),
    };
    const res = await dispatch(getAllSubmitterLoanFormThunk(paramCreater(paramData)));
    if (res.payload.status !== 200) {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
      const bankParams = {
        bankStatus: true,
      };
      const codeParams = {
        status: true,
      };
      const [bankRes, codeRes] = await Promise.all([
        dispatch(getAllBanks(paramCreater(bankParams))),
        dispatch(getAllCodes(paramCreater(codeParams))),
      ]);
      if (bankRes.payload.status === 200 && codeRes.payload.status === 200) {
        const tempBankList = bankRes.payload.data.data.map((item) => ({
          [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
          title: item.bankName,
        }));
        const tempCodeList = codeRes.payload.data.data.map((item) => ({
          [Constants.MONGOOSE_ID]: item[Constants.MONGOOSE_ID],
          title: item.code,
        }));
        const tempFilters = [...filters];
        tempFilters[3].list = [...tempFilters[3].list, ...tempBankList];
        tempFilters[4].list = [...tempFilters[4].list, ...tempCodeList];
        setBankList(tempBankList);
        setCodeList(tempCodeList);
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditModal({ ...editModal, [name]: value });
  };

  const handleUpdateLeadData = async () => {
    setEditLoading(true);
    const body = {
      ...(editModal.sourceOfLead !== editModal.data.sourceOfLead && {
        sourceOfLead: editModal.sourceOfLead,
      }),
      ...(editModal.imd !== editModal.data.imd && { imd: editModal.imd }),
      ...(editModal.bank !== editModal.data.bank && { bank: editModal.bank }),
      ...(editModal.code !== editModal.data.code && { code: editModal.code }),
      ...(editModal.currentStatus !== editModal.data.currentStatus && {
        currentStatus: editModal.currentStatus,
      }),
      ...(editModal.remark !== editModal.data.remark && { remark: editModal.remark }),
      ...(editModal.status !== editModal.data.status && { status: editModal.status }),
      ...(editModal.dateOfDisbursment !== editModal.data.dateOfDisbursment && {
        dateOfDisbursment: editModal.dateOfDisbursment,
      }),
    };
    const res = await dispatch(updateLeadDataThunk({ id: editModal.id, body }));
    if (res.payload.status === 200) {
      dispatch(
        openSnackbar({
          message: res.payload.data.message,
          notificationType: Constants.NOTIFICATION_SUCCESS,
        })
      );
      handleCloseEditModal();
      await dispatch(updateLoanData(res.payload.data.data));
    } else {
      setError(res.payload.data.message);
    }
    setEditLoading(false);
  };

  const handleTablePagination = async () => {
    const data = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(getAllSubmitterLoanFormThunk(paramCreater(data)));
    if (res.payload.status === 200) {
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  const handleExport = async (format) => {
    const currentDate = new Date();

    const filename = `Finvibes_lead_bay_${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}.${format}`;

    const res = await dispatch(exportLoanThunk());
    const url = window.URL.createObjectURL(res.payload);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const debouncedHandleSearch = (e, i) => {
    const temp = [...search];
    temp[i].list = [{ [Constants.MONGOOSE_ID]: e.target.value, title: e.target.value }];
    temp[i].selectedValue = e.target.value;
    setSearch(temp);
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => handleFilter(temp), 500);
  };

  const handleFilterChange = (e) => {
    const temp = [...filters];
    const { value, name } = e.target;
    const index = filters.findIndex((filter) => filter.inputLabel === name);
    temp[index].selectedValue = value;
    setFilters(temp);
    handleFilter(search, temp);
  };

  const handleResetFilter = () => {
    const tempFilters = [...search];
    tempFilters[0].selectedValue = "";
    tempFilters[1].selectedValue = "";
    tempFilters[2].selectedValue = "";
    setSearch(tempFilters);
    handleFilter(tempFilters);
  };

  return (
    <>
      <MDBox
        display="flex"
        alignItems="start"
        flexWrap="wrap"
        sx={{ flexDirection: "row", mr: 2, mb: 1 }}
        style={{ width: "100%" }}
      >
        {/* Filters */}
        {filters?.map((val) => (
          <FilterDropdown
            label={val.inputLabel}
            name={val.inputLabel}
            defaultValue={val?.selectedValue}
            value={val?.selectedValue}
            handleChange={handleFilterChange}
            menu={val.list}
            key={val.inputLabel}
            maxContent={defaultData.MEDIUM_CONTENT_LENGTH}
          />
        ))}
        {/* search */}
        {search
          .filter((filter) => filter.checked)
          .map((filter) => (
            <CustomSearch
              isLoading={filter.isLoading}
              options={filter.list}
              value={filter.selectedValue}
              debouncedHandleSearch={(e) => debouncedHandleSearch(e, filter.index)}
              placeholder={`Search ${filter.title}`}
              label={filter.title}
              width={pxToRem(300)}
              marginRight={pxToRem(10)}
              freeSolos
            />
          ))}
        <MDBox
          sx={{
            mt: 5.5,
            mr: 1,
            ml: 0,
          }}
        >
          <BasicButton
            title="Export"
            icon={Icons.EXPORT}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={() => handleExport("xlsx")}
          />
        </MDBox>
        <ResetFilterButton handleReset={handleResetFilter} />
        <SearchMenu searchOptions={search} setSearchOptions={setSearch} />
      </MDBox>

      <MDBox mt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loading={loanData.dataLoading}
          handleTablePagination={handleTablePagination}
          currentPage={tablePagination.page}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>

      {/* Edit Lead Data */}
      <BasicModal
        open={editModal.open}
        handleClose={handleCloseEditModal}
        title={ModalContent.EDIT_LEAD_DATA}
        actionButton={editLoading ? ButtonTitles.UPDATE_LOADING : ButtonTitles.UPDATE}
        handleAction={handleUpdateLeadData}
      >
        <ConfigDropdown
          label="Source of Lead*"
          name="sourceOfLead"
          id="sourceOfLead"
          value={editModal.sourceOfLead}
          handleChange={(e, value) => handleChange({ target: { name: "sourceOfLead", value } })}
          menu={leadSources}
          error={Boolean(error?.sourceOfLead)}
          helperText={error?.sourceOfLead}
          minWidth={pxToRem(200)}
        />
        <FTextField
          label="IMD*"
          placeholder="Enter IMD"
          id="imd"
          name="imd"
          type="text"
          error={Boolean(error?.imd)}
          helperText={error?.imd}
          value={editModal.imd}
          handleChange={handleChange}
          marginBottom={2}
        />
        <FDropdown2
          label="Bank*"
          id="bank"
          name="bank"
          value={editModal.bank}
          defaultValue=""
          options={bankList || []}
          error={Boolean(error?.bank)}
          helperText={error?.bank}
          handleChange={handleChange}
          marginBottom={pxToRem(10)}
        />
        <FDropdown2
          label="Code*"
          id="code"
          name="code"
          value={editModal.code}
          defaultValue=""
          options={codeList || []}
          error={Boolean(error?.code)}
          helperText={error?.code}
          handleChange={handleChange}
          marginBottom={pxToRem(10)}
        />

        <ConfigDropdown
          label="Current Status*"
          name="currentStatus"
          id="currentStatus"
          value={editModal.currentStatus}
          handleChange={(e, value) => handleChange({ target: { name: "currentStatus", value } })}
          menu={currentStatus}
          error={Boolean(error?.currentStatus)}
          helperText={error?.currentStatus}
          minWidth={pxToRem(200)}
        />
        {editModal.currentStatus === "Disbursement" && (
          <FTextField
            label="Date Of Disbursement*"
            placeholder="Enter Date Of Disbursement"
            id="dateOfDisbursment"
            name="dateOfDisbursment"
            type="date"
            error={Boolean(error?.dateOfDisbursment)}
            helperText={error?.dateOfDisbursment}
            value={editModal.dateOfDisbursment}
            handleChange={handleChange}
            marginBottom={2}
          />
        )}
        <FTextField
          label="Remark*"
          placeholder="Enter Remark"
          id="remark"
          name="remark"
          type="textarea"
          rows={3}
          error={Boolean(error?.remark)}
          helperText={error?.remark}
          value={editModal.remark}
          handleChange={handleChange}
          marginBottom={2}
        />
        <ConfigDropdown
          label="Status*"
          name="status"
          id="status"
          value={editModal.status}
          handleChange={(e, value) => handleChange({ target: { name: "status", value } })}
          menu={["open", "close"]}
          error={Boolean(error?.status)}
          helperText={error?.status}
          minWidth={pxToRem(200)}
        />
      </BasicModal>

      {openEdit && (
        <UpdateLeadBay
          selectedCardId={leadyBayId}
          leadBayData={updateLeadbay}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          screenIndex={screenIndex}
          handleFilter={handleFilter}
        />
      )}
    </>
  );
}

export default feedbacks;
