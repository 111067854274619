import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import MobileUsers from "layouts/mobileUsers/data/mobileUsers";
import DataTable from "examples/Tables/DataTable";
import SearchMenu from "examples/multiSearchBar/MultiSearchBar";
import ResetFilterButton from "components/Buttons/ResetButton";

// Constant
import Constants, { defaultData, PageTitles } from "utils/Constants";

// Redux component
import { openSnackbar } from "redux/Slice/Notification";
import { useDispatch } from "react-redux";
import getAllMobileUsers from "redux/Thunks/MobileUser";
import { activeateUserThunk } from "redux/Thunks/UserManagement";
import CustomSearch from "components/Search/Search";
import pxToRem from "assets/theme/functions/pxToRem";

function feedbacks() {
  const [filters, setFilters] = useState([
    {
      inputLabel: "Search By First Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "First Name",
      checked: true,
      id: "firstName",
    },
    {
      inputLabel: "Search By Last Name",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Last Name",
      checked: false,
      id: "lastName",
    },
    {
      inputLabel: "Search By Mobile Number",
      list: [],
      selectedValue: "",
      isLoading: false,
      title: "Mobile Number",
      checked: false,
      id: "mobileNumber",
    },
  ]);
  const [mobileUsers, setMobileUsers] = useState();
  const [loading, setLoading] = useState("pending");
  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const dispatch = useDispatch();
  let debounceTimeout;

  const handleFilter = async (temp = filters) => {
    setLoading(Constants.PENDING);
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
      ...(temp[0].selectedValue !== "" && { firstName: temp[0].selectedValue }),
      ...(temp[1].selectedValue !== "" && { lastName: temp[1].selectedValue }),
      ...(temp[2].selectedValue !== "" && {
        mobileNumber: temp[2].selectedValue,
      }),
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getAllMobileUsers(data));
    const firstNameList = [];
    const lastNameList = [];
    const mobileNumberList = [];

    if (res.payload.status === 200) {
      res.payload.data.data.forEach((item) => {
        const { firstName, lastName, mobileNumber } = item;
        if (firstName && firstName !== "") {
          firstNameList.push({ [Constants.MONGOOSE_ID]: firstName, title: firstName });
        }
        if (lastName && lastName !== "") {
          lastNameList.push({ [Constants.MONGOOSE_ID]: lastName, title: lastName });
        }

        if (mobileNumber && mobileNumber !== "") {
          mobileNumberList.push({ [Constants.MONGOOSE_ID]: mobileNumber, title: mobileNumber });
        }
      });
      setMobileUsers(res.payload.data.data);
      setLoading(Constants.FULFILLED);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const handleActivateUser = async (id, isActive) => {
    const res = await dispatch(activeateUserThunk({ id, body: { isActive } }));
    if (res.payload.status === 200) {
      const userIndex = mobileUsers.findIndex((user) => user[Constants.MONGOOSE_ID] === id);
      const tempUserList = [...mobileUsers];
      tempUserList[userIndex].isActive = isActive;
      setMobileUsers(tempUserList);
      dispatch(
        openSnackbar({
          message: isActive ? Constants.USER_ACTIVATED_SUCCESS : Constants.USER_DEACTIVATED_SUCCESS,
          notificationType: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  const { columns, rows } = MobileUsers(mobileUsers, handleActivateUser);

  const handleTablePagination = async () => {
    const data = new URLSearchParams({
      page: next + 1,
      perPage: tablePagination.perPage,
    });
    const res = await dispatch(getAllMobileUsers(data));
    if (res.payload.status === 200) {
      setMobileUsers([...mobileUsers, ...res.payload.data.data]);
      setNext(res.payload.data.data.length > 0 ? next + 1 : next);
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const debouncedHandleSearch = (e, i) => {
    const temp = [...filters];
    temp[i].list = [{ [Constants.MONGOOSE_ID]: e.target.value, title: e.target.value }];
    temp[i].selectedValue = e.target.value;
    setFilters(temp);
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(async () => handleFilter(temp), 500);
  };

  const handleResetFilter = () => {
    const tempFilters = [...filters];
    tempFilters[0].selectedValue = "";
    tempFilters[1].selectedValue = "";
    tempFilters[2].selectedValue = "";
    setFilters(tempFilters);
    handleFilter(tempFilters);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.MOBILE_USERS} />
      </MDBox>

      <MDBox
        display="flex"
        alignItems="start"
        flexWrap="wrap"
        sx={{ flexDirection: "row", mr: 2, mb: 1 }}
        style={{ width: "100%" }}
      >
        {/* search */}
        {filters
          .filter((filter) => filter.checked)
          .map((filter, i) => (
            <CustomSearch
              isLoading={filter.isLoading}
              options={filter.list}
              value={filter.selectedValue}
              debouncedHandleSearch={(e) => debouncedHandleSearch(e, i)}
              placeholder={`Search ${filter.title}`}
              label={filter.title}
              width={pxToRem(300)}
              marginRight={pxToRem(10)}
              freeSolos
            />
          ))}
        <ResetFilterButton handleReset={handleResetFilter} />
        <SearchMenu searchOptions={filters} setSearchOptions={setFilters} />
      </MDBox>

      <MDBox mt={2}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
          showTotalEntries={false}
          noEndBorder
          loading={loading}
          currentPage={tablePagination.page}
          handleTablePagination={handleTablePagination}
          handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default feedbacks;
