import { createAsyncThunk } from "@reduxjs/toolkit";
import Sessions from "utils/Sessions";
import ApiService from "../ApiService/ApiService";

const submitBankOpeningForm = createAsyncThunk("submit-bank-opening", async (body) => {
  const res = await ApiService.post(
    `bank-opening`,
    { ...body },
    {
      headers: { Authorization: `Bearer ${Sessions.userToken}` },
    }
  )
    .then((r) => r)
    .catch((err) => err.response);
  return res;
});

export const getAllBanksOpeningList = createAsyncThunk("bank-opening/list", async (param) => {
  const res = await ApiService.get(`bank-opening?${param}`, {
    headers: { Authorization: `Bearer ${Sessions.userToken}` },
  })
    .then((r) => r)
    .catch((err) => err.response);
  const params = new URLSearchParams(param);
  const page = params.get("page");

  return page === "0"
    ? { data: res.data, type: "add", status: res.status }
    : { data: res.data, type: "append", status: res.status };
});

export default submitBankOpeningForm;
