import React from "react";
import PropTypes from "prop-types";

// MUI Components
import { Box } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Constant
import { Colors } from "utils/Constants";

const getTextLabel = (label, value, total) => {
  if (label === "percentage") {
    if (value !== 0) {
      return `${Math.floor((value / total) * 100)}%`;
    }
    return "0%";
  }
  return value;
};

function DoughnutPieChart({
  data,
  title,
  totalCount,
  isapplyFilter,
  filterData,
  handleFilterChange,
  label,
}) {
  let startAngle = -90;
  const total = data.reduce((acc, slice) => acc + slice.value, 0);

  const singleSliceData = data.find((slice) => slice.value === totalCount);
  let isSingleSlice = false;
  if (singleSliceData && totalCount !== 0) {
    isSingleSlice = true;
  }

  console.log(
    "isSingleSlice",
    data,
    title,
    totalCount,
    isapplyFilter,
    filterData,
    handleFilterChange,
    label
  );

  return (
    <MDBox
      sx={{
        display: "flex",
        gap: { lg: pxToRem(50), md: pxToRem(50), sm: pxToRem(10), xs: pxToRem(10) },
        flexWrap: "wrap",
        justifyContent: { xs: "center", lg: "start", md: "start", sm: "center" },
        alignItems: "center",
      }}
    >
      <svg width="300" height="300" style={{ marginTop: pxToRem(35) }}>
        {totalCount !== 0 && total === totalCount && !isSingleSlice ? (
          data.map((slice) => {
            const percentage = (slice.value / totalCount) * 100;
            const endAngle = startAngle + (percentage * 360) / 100;
            const largeArcFlag = percentage > 50 ? 1 : 0;
            // Calculate outer slice coordinates
            const outerStartX = Math.cos((startAngle * Math.PI) / 180) * 100 + 150;
            const outerStartY = Math.sin((startAngle * Math.PI) / 180) * 100 + 150;
            const outerEndX = Math.cos((endAngle * Math.PI) / 180) * 100 + 150;
            const outerEndY = Math.sin((endAngle * Math.PI) / 180) * 100 + 150;

            // Calculate inner slice coordinates
            const innerStartX = Math.cos((startAngle * Math.PI) / 180) * 80 + 150;
            const innerStartY = Math.sin((startAngle * Math.PI) / 180) * 80 + 150;
            const innerEndX = Math.cos((endAngle * Math.PI) / 180) * 80 + 150;
            const innerEndY = Math.sin((endAngle * Math.PI) / 180) * 80 + 150;

            // Calculate text position
            const textAngle = (startAngle + endAngle) / 2;
            const textRadius = 90;
            const textX = Math.cos((textAngle * Math.PI) / 180) * textRadius + 150;
            const textY = Math.sin((textAngle * Math.PI) / 180) * textRadius + 155;

            startAngle = endAngle;
            return (
              <g key={slice.name} style={{ cursor: "pointer" }}>
                {/* Outer border */}
                <path
                  d={`M ${outerStartX} ${outerStartY} A 100 100 0 ${largeArcFlag} 1 ${outerEndX} ${outerEndY}`}
                  fill="none"
                  stroke={slice.color}
                  strokeWidth="40"
                />
                {/* Inner border */}
                <path
                  d={`M ${innerEndX} ${innerEndY} A 80 80 0 ${largeArcFlag} 0 ${innerStartX} ${innerStartY}`}
                  fill="none"
                  stroke={slice.color}
                  strokeWidth="40"
                />
                {slice.value > 0 && (
                  <text
                    x={textX}
                    y={textY}
                    textAnchor="middle"
                    fill={slice.textColor}
                    style={{ fontWeight: "600" }}
                  >
                    {getTextLabel(label, slice.value, totalCount)}
                  </text>
                )}
                <text
                  x="150"
                  y="165"
                  textAnchor="middle"
                  fill="#000"
                  style={{ fontWeight: "800", fontSize: "42px", pointerEvents: "none" }}
                >
                  {label === "percentage" ? null : totalCount}
                </text>
              </g>
            );
          })
        ) : (
          <>
            <circle
              cx="150"
              cy="150"
              r="125"
              fill={isSingleSlice ? singleSliceData.color : Colors.LIGHT_GRAY}
              style={{ cursor: isSingleSlice ? "pointer" : "default" }}
            />
            <circle cx="150" cy="150" r="65" fill="#FFFFFF" />
            <text
              x="150"
              y="165"
              textAnchor="middle"
              fill="#000"
              style={{ fontWeight: "800", fontSize: "42px", pointerEvents: "none" }}
            >
              {label === "percentage" ? null : totalCount}
            </text>
            {isSingleSlice && (
              <text
                x="150"
                y="250"
                textAnchor="middle"
                fill={singleSliceData.textColor}
                style={{ fontWeight: "600" }}
              >
                {label === "percentage" ? "100%" : singleSliceData.value}
              </text>
            )}
          </>
        )}
      </svg>
      <MDBox>
        <MDTypography
          sx={{
            fontWeight: "700",
            fontSize: pxToRem(24),
            lineHeight: pxToRem(38),
          }}
        >
          {title}
        </MDTypography>
        {data.map((item) => (
          <MDBox
            key={item.name}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: pxToRem(20),
              marginTop: pxToRem(20),
            }}
          >
            <Box
              component="span"
              backgroundColor={item.color}
              sx={{
                borderRadius: pxToRem(5),
                width: pxToRem(20),
                height: pxToRem(20),
                display: "inline-block",
              }}
            />
            <MDTypography
              sx={{
                fontSize: pxToRem(16),
                fontWeight: "600",
                color: "#000000",
              }}
            >
              {item.value !== 0 ? `${((item.value / totalCount) * 100).toFixed(2)}%` : "0.00%"}
            </MDTypography>
            <MDTypography
              sx={{
                fontSize: pxToRem(16),
                fontWeight: "600",
                color: "#000000",
              }}
            >
              {item.name}
            </MDTypography>
          </MDBox>
        ))}
      </MDBox>
    </MDBox>
  );
}

DoughnutPieChart.defaultProps = {
  isapplyFilter: false,
  filterData: {
    selectedValue: "",
    menu: [],
    name: "select",
  },
  handleFilterChange: () => {},
  label: "text",
};

DoughnutPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  isapplyFilter: PropTypes.bool,
  filterData: PropTypes.shape({
    selectedValue: PropTypes.string,
    name: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.object),
  }),
  handleFilterChange: PropTypes.func,
  label: PropTypes.string,
};

export default DoughnutPieChart;
